// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* CarAnimation.css */
@keyframes moveCar {
    0% { transform: translate(0, 0); }
    100% { transform: translate(100vw, 100vh); }
  }
  
  .car {
    position: absolute;
    width: 50px;
    height: 40px;
    /* background-color: #ff0000; Red car */
    background-image: url('http://localhost:3000/smallcar.webp');
    background-size: cover;

    animation: moveCar linear infinite;
    opacity: 0.5;
  }
  
  .car-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1; /* Set a negative z-index to push it behind */
    overflow: hidden; /* Prevent cars from overflowing */
  }`, "",{"version":3,"sources":["webpack://./src/CarAnimation.css"],"names":[],"mappings":"AAAA,qBAAqB;AACrB;IACI,KAAK,0BAA0B,EAAE;IACjC,OAAO,kCAAkC,EAAE;EAC7C;;EAEA;IACE,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,uCAAuC;IACvC,4DAA4D;IAC5D,sBAAsB;;IAEtB,kCAAkC;IAClC,YAAY;EACd;;EAEA;IACE,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,QAAQ;IACR,SAAS;IACT,WAAW,EAAE,6CAA6C;IAC1D,gBAAgB,EAAE,kCAAkC;EACtD","sourcesContent":["/* CarAnimation.css */\n@keyframes moveCar {\n    0% { transform: translate(0, 0); }\n    100% { transform: translate(100vw, 100vh); }\n  }\n  \n  .car {\n    position: absolute;\n    width: 50px;\n    height: 40px;\n    /* background-color: #ff0000; Red car */\n    background-image: url('http://localhost:3000/smallcar.webp');\n    background-size: cover;\n\n    animation: moveCar linear infinite;\n    opacity: 0.5;\n  }\n  \n  .car-container {\n    position: absolute;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    z-index: -1; /* Set a negative z-index to push it behind */\n    overflow: hidden; /* Prevent cars from overflowing */\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
