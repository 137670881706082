// CarAnimation.js
import React, { useEffect, useState } from "react";
import "./CarAnimation.css";

const CarAnimation = () => {
  const [cars, setCars] = useState([]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCars((prevCars) => [
        ...prevCars,
        {
          id: Math.random(),
          animationDuration: `${Math.random() * 10 + 5}s`, // Random duration between 5s and 15s
          left: `${Math.random() * 100}%`,
          top: `${Math.random() * 100}%`,
        },
      ]);
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="car-container">
      {cars.map((car) => (
        <div
          key={car.id}
          className="car"
          style={{
            animationDuration: car.animationDuration,
            left: car.left,
            top: car.top,
          }}
        />
      ))}
    </div>
  );
};

export default CarAnimation;
