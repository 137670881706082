import React, { useState } from "react";
import {
  Box,
  Typography,
  Card,
  CardMedia,
  CardContent,
  Button,
  useMediaQuery,
  useTheme,
  IconButton,
  List,
  ListItem,
  Divider,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

const Carousel = () => {
  const classes = useStyles();
  const [currentIndex, setCurrentIndex] = useState(0);
  const data = [
    {
      title: "Creating bookings",
      description: "Simply specify date, insurance and location.",
      image: "/screenshot2.jpg",
    },
    {
      title: "Pick a slot",
      description: "On the left are your vehicles. On the top are the dates.",
      image: "/screenshot.jpg",
    },
    // {
    //   title: "Item 3",
    //   description: "Description for Item 3",
    //   image: "/screenshot.jpg",
    // },
  ];

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === data.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? data.length - 1 : prevIndex - 1
    );
  };

  return (
    <Box className={classes.carousel}>
      {currentIndex > 0 && (
        <IconButton onClick={handlePrev}>
          <NavigateBeforeIcon />
        </IconButton>
      )}

      <Box className={classes.textSide}>
        <Typography variant="h5" component="h2">
          {data[currentIndex].title}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          {data[currentIndex].description}
        </Typography>
        {/* <Button size="small">Learn More</Button> */}
      </Box>
      <Box className={classes.imageSide}>
        <Card className={classes.card}>
          <CardMedia
            component="img"
            image={data[currentIndex].image}
            title={data[currentIndex].title}
          />
        </Card>
      </Box>
      <IconButton onClick={handleNext}>
        <NavigateNextIcon />
      </IconButton>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  section: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    textAlign: "center",
    padding: theme.spacing(2),
  },
  screenshotsContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: theme.spacing(4),
  },

  screenshot: {
    width: "10%", // Adjust this to scale down the size of the cards
    boxShadow: theme.shadows[4],
    borderRadius: theme.shape.borderRadius,
    margin: theme.spacing(1), // Added some space between the cards
    "&:not(:last-child)": {
      marginRight: theme.spacing(2),
    },
    "&:nth-child(2)": {
      transform: "scale(1.1)",
    },

    overflow: "hidden", // Ensure the enlargement doesn't exceed the card boundary
    "&:hover": {
      "& $screenshotMedia": {
        // Target the screenshotMedia class on hover
        transform: "scale(1.05)", // Adjust scale value to desired enlargement
      },
    },
  },
  screenshotMedia: {
    transition: "transform 0.3s ease-in-out", // Smooth transition for the transform property

    width: "100%", // Ensure the image covers the card width
    // Removed the height property to maintain aspect ratio
  },

  pricingCard: {
    minWidth: 275,
    margin: theme.spacing(2),
  },
  pricingContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  //   carousel

  carousel: {
    display: "flex",
    alignItems: "center",
    width: "80%",
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  textSide: {
    flex: 1, // Take remaining space in the row
    textAlign: "center",
  },
  imageSide: {
    flex: 1, // Take remaining space in the row
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  card: {
    width: "30%", // Adjust card width as needed
    // transition: "transform 0.3s ease-in-out", // Move this line outside the hover state
    "&:hover": {
      transform: "scale(1.5)",
      transition: "transform 0.3s ease-in-out", // Add this line
    },
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  iconButton: {
    alignSelf: "center",
  },
}));

const SynopsisSection = () => {
  const classes = useStyles();

  return (
    <Box className={classes.section} id="synopsis">
      <Box>
        <Typography variant="h4" component="h1">
          Synopsis
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Discover Our Services
        </Typography>
        <Typography variant="body1" paragraph>
          We offer a comprehensive suite of services designed to streamline your
          booking management needs. Our offerings include:
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>Mobile App for Booking Management:</strong> Manage your
          bookings on the go with our intuitive mobile app. Keep track of
          appointments and schedules effortlessly.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>Ledger for Payment Recording:</strong> Accurately record
          payments and financial transactions associated with your bookings
          using our user-friendly ledger system.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>CRM Integration:</strong> Enhance customer relationships with
          our CRM features. Call or email clients directly from your mobile
          device to confirm bookings and interact efficiently.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>Real-Time Statistics:</strong> Get insights into important
          financial metrics like outstanding payments, monthly overviews, and
          other crucial data that help in decision-making.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>Feature Requests - Custom Analytics:</strong>
          Catering to specific needs, we provide the option for clients to
          request more custom analytics. Understanding that each business has
          unique requirements, <br /> our team is ready to develop tailored
          analytics solutions at an additional cost. This ensures that you have
          the precise data you need to make informed decisions. 
        </Typography>
      </Box>
    </Box>
  );
};

const App = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMediumUp = useMediaQuery(theme.breakpoints.up("md"));
  const section1_images = [
    {
      title: "Creating bookings",
      description: "Simply specify date, insurance and location.",
      image: "/screenshot2.jpg",
    },
    {
      title: "Pick a slot",
      description: "On the left are your vehicles. On the top are the dates.",
      image: "/screenshot.jpg",
    },
    {
      title: "Item 3",
      description: "Description for Item 3",
      image: "/vid2.gif",
    },
  ];
  return (
    <Box>
      {/* Section 1 */}
      <Box className={classes.section} id="section1">
        <Box pb={2}>
          <Typography variant="h2" component="h1">
            Your Future Rental Manager
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            Discover insights into your rental assets.
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            Manage booking requests.
          </Typography>
        </Box>
        <Box className={classes.screenshotsContainer}>
          {Array.from({ length: 3 }, (_, index) => (
            <Card
              className={classes.screenshot}
              key={index}
              style={{
                transform: index === 1 && isMediumUp ? "scale(1.1)" : "none",
                transition: "transform 0.3s ease-in-out",
                "&:hover": {
                  transform: "scale(1.15)",
                },
              }}
            >
              <CardMedia
                component="img" // Use 'img' as component for better control
                className={classes.screenshotMedia}
                image={section1_images[index].image}
                // image="/screenshot.jpg" // Assuming the file is named 'screenshot.jpg' directly in the public folder
                title={`Mobile Screen ${index + 1}`}
              />
            </Card>
          ))}
        </Box>
      </Box>
      <SynopsisSection />
      {/* Section 2 Placeholder */}
      <Box className={classes.section} id="section2">
        <Box>
          <Typography variant="h4" component="h1">
            How it works
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            Click through the images to see how the app works.
          </Typography>
        </Box>
        <Carousel />
      </Box>

      {/* Section 3 - Pricing */}
      <Box className={classes.section} id="section3">
        <Box>
          <Typography variant="h4" component="h1">
            Pricing
          </Typography>
          {/* <Typography variant="subtitle1" gutterBottom>
            Extras comming soon.
          </Typography> */}
        </Box>
        {["Basic"].map((plan, index) => (
          <Card className={classes.pricingCard} key={plan}>
            <CardContent className={classes.pricingContent}>
              <Typography variant="h5" component="h3">
                {plan}
              </Typography>
              <Typography color="textSecondary">{`4€/asset/month`}</Typography>
              <Typography variant="body2">
                {`${plan} features for ${plan.toLowerCase()} use.`}
              </Typography>
              <List>
                <ListItem>Web App</ListItem>
                <ListItem>Mobile App</ListItem>
                <Divider />
                <ListItem>Unlimited Staff Members</ListItem>
                <ListItem>Unlimited Assets</ListItem>
                <ListItem>Unlimited Bookings</ListItem>
                <Divider />
                <ListItem>Create Bookings</ListItem>
                <ListItem>Booking Overview</ListItem>
                <ListItem>Add/remove assets</ListItem>
                <Divider />
                <ListItem>Custom Asset Pricing</ListItem>
                <ListItem>Custom Insurance Pricing</ListItem>
                <ListItem>Custom Location Pricing</ListItem>
                <Divider />
                <ListItem>Bussiness Metrics</ListItem>

                {/* Add more features as needed */}
              </List>
              {/* <Button size="small">Learn More</Button> */}
            </CardContent>
          </Card>
        ))}
      </Box>
    </Box>
  );
};

export default App;
