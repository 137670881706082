// src/Root.js

import React from "react";
import App from "./App";
import { QCTMS2Provider } from "./contextapp";

const Root = () => {
  return (
    <QCTMS2Provider>
      <App />
    </QCTMS2Provider>
  );
};

export default Root;
