import React from "react";
import { Link } from "react-router-dom";
import { AppBar, Box, Toolbar, Button } from "@mui/material";
import { useQCTMS2 } from "./contextapp"; // Import your context
import { useLocation } from "react-router-dom";
// import ProjectManager from "./managers/ProjectManager";

// import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const Header = () => {
  //   const navigate = useNavigate();
  const location = useLocation();

  const {
    // update_user_token,
    setIsSidePanelOpen,
    isSidePanelOpen,
    // FOR RESETING CONTEXT
    setGroups,
    setSelectedFileIndex,
    setSelectedProject,
    setSelectedGroup,
    setSelectedDocumentName,
    setTreeData,
    activeSection,
    setActiveSection,
  } = useQCTMS2();

  //   const contextValues = useQCTMS2();
  //   const project_manager = new ProjectManager(contextValues);

  const shouldDisplayHeader = () => {
    const show =
      location.pathname !== "/login" && location.pathname !== "/initiallogin";
    return show;
  };

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };
  <Button
    variant="contained"
    color="primary"
    onClick={() => scrollToSection("section2")}
  >
    Go to Section 2
  </Button>;
  return (
    <>
      {shouldDisplayHeader() && (
        <AppBar
          position="fixed"
          color="default"
          elevation={0}
          sx={{ borderBottom: 1, borderColor: "divider" }}
        >
          <Toolbar>
            {/* Left-aligned items */}
            <Box sx={{ flexGrow: 1, display: "flex" }}>
              {/* ... other buttons ... */}
              <Button
                color={activeSection === "section1" ? "primary" : "inherit"}
                sx={{ my: 2, display: "block" }}
                onClick={() => {
                  setActiveSection("section1");
                  scrollToSection("section1");
                }}
              >
                Home
              </Button>
              <Button
                color={activeSection === "synopsis" ? "primary" : "inherit"}
                sx={{ my: 2, display: "block" }}
                onClick={() => {
                  setActiveSection("synopsis");
                  scrollToSection("synopsis");
                }}
              >
                Synopsis
              </Button>
              <Button
                color={activeSection === "section2" ? "primary" : "inherit"}
                sx={{ my: 2, display: "block" }}
                onClick={() => {
                  setActiveSection("section2");

                  scrollToSection("section2");
                }}
              >
                How it works
              </Button>
              <Button
                color={activeSection === "section3" ? "primary" : "inherit"}
                sx={{ my: 2, display: "block" }}
                onClick={() => {
                  setActiveSection("section3");

                  scrollToSection("section3");
                }}
              >
                Pricing
              </Button>
            </Box>

            <Button
              color="inherit"
              onClick={() => {
                window.open("https://manage.rentalman.app", "_blank");
              }}
              sx={{ my: 2, display: "block" }}
            >
              Bussiness Portal
            </Button>
          </Toolbar>
        </AppBar>
      )}
    </>
  );
};

export default Header;
