import React, { useContext, useReducer, useState } from "react";
import PropTypes from "prop-types";

const QCTMS2Context = React.createContext();
// const [showProjectAddForm, setShowProjectAddForm] = useState(false);

const QCTMS2Reducer = (state, action) => {
  switch (action.type) {
    case "setIsSidePanelOpen":
      return { ...state, isSidePanelOpen: action.payload };

    // Auth
    case "update_user_token":
      return { ...state, user_token: action.payload };
    case "update_user_id":
      return { ...state, user_id: action.payload };
    case "update_user_role":
      return { ...state, user_role: action.payload };
    case "update_user_loggen_in":
      return { ...state, user_logged_in: action.payload };

    case "setLoggedInUserName":
      return { ...state, logged_in_user_name: action.payload };

    case "setLoggedInUserEmail":
      return { ...state, logged_in_user_email: action.payload };

    case "update_is_system_admin":
      return { ...state, is_system_admin: action.payload };
    case "update_system_admin_permisions":
      return { ...state, system_admin_permisions: action.payload };

    case "setUserDefaultProject":
      return { ...state, user_default_project: action.payload };
    // based on project a user clicked on in the project list
    // current persmissions must be reset.

    case "update_current_user_permissions":
      return { ...state, current_user_permissions: action.payload };

    case "update_project_id":
      return { ...state, project_id: action.payload };
    case "update_project_name":
      return { ...state, project_name: action.payload };
    case "setSelectedDocumentName":
      return { ...state, selectedDocumentName: action.payload };
    case "setselectedFolderId":
      return { ...state, folder_id: action.payload };
    case "setSelectedFileIndex":
      return { ...state, selectedFileIndex: action.payload };
    case "setTreeData":
      return { ...state, treeData: action.payload };
    case "setOpenFolderList":
      return { ...state, open_folder_list: action.payload };
    case "setSelectedFolderName":
      return { ...state, selectedFolderName: action.payload };
    case "setisFileMoveDialogOpen":
      return { ...state, isFileMoveDialogOpen: action.payload };
    case "setIsEditPlaceholderDialogOpen":
      return { ...state, isEditPlaceholderDialogOpen: action.payload };
    case "setselectedPlaceholderId":
      return { ...state, placeholder_id: action.payload };
    case "setselectedDocumentType":
      return { ...state, documentType: action.payload };
    // ADMIN
    case "setSelectedUser":
      return { ...state, selectedUser: action.payload };
    case "setselectedRoleAdmin":
      return { ...state, selectedRole: action.payload };
    case "setSelectedProject":
      return { ...state, selectedProject: action.payload };
    case "setSelectedGroup":
      return { ...state, selectedGroup: action.payload };
    case "setUserRoles":
      return { ...state, userRoles: action.payload };
    case "setProjects":
      return { ...state, projects: action.payload };
    case "setGroups":
      return { ...state, groups: action.payload };
    case "setGroupTypes":
      return { ...state, groupTypes: action.payload };

    // PROJECTS

    case "setShowProjectAddForm":
      return { ...state, showProjectAddForm: action.payload };

    case "setProjectUsers":
      return { ...state, project_users: action.payload };

    // PERMISSIONS
    case "setDocumentAdd":
      return { ...state, documentAdd: action.payload };

    case "setDocumentDelete":
      return { ...state, documentDelete: action.payload };

    case "setDocumentLock":
      return { ...state, documentLock: action.payload };

    case "setDocumentUnlock":
      return { ...state, documentUnlock: action.payload };

    case "setDocumentDownload":
      return { ...state, documentDownload: action.payload };

    case "setDocumentMove":
      return { ...state, documentMove: action.payload };

    case "setDocumentEditMetadata":
      return { ...state, documentEditMetadata: action.payload };

    case "setDocumentViewDocTree":
      return { ...state, documentViewDocTree: action.payload };

    case "setProjectSearchText":
      return { ...state, projectSearchText: action.payload };
    // NOTIFICATIONS
    case "setSnackbarOpen":
      return { ...state, snackbarOpen: action.payload };
    case "setSnackbarMessage":
      return { ...state, snackbarMessage: action.payload };

    case "SetActiveSection":
      return { ...state, activeSection: action.payload };
    default:
      return state;
  }
};

export const useQCTMS2 = () => {
  return useContext(QCTMS2Context);
};

export const QCTMS2Provider = ({ children }) => {
  const initState = {
    isSidePanelOpen: false,
    user_token: null,
    user_id: null,
    user_role: null,
    user_logged_in: false,

    logged_in_user_name: null,
    logged_in_user_email: null,
    user_default_project: null,

    project_id: 200,
    project_name: null,
    selectedDocumentName: null,
    folder_id: null,
    selectedFileIndex: null,
    treeData: [],
    open_folder_list: ["root"],
    selectedFolderName: null,
    isFileMoveDialogOpen: false,
    isEditPlaceholderDialogOpen: false,
    placeholder_id: null,
    documentType: null,
    is_system_admin: false,
    system_admin_permisions: {
      document_view: true,
      group_add: true,
      group_edit: true,
      group_show: true,
      log_show: true,
      message_send: true,
      project_add: false,
      project_edit: true,
      project_show: true,
      user_details: true,
      user_show: true,
    },
    current_user_permissions: {},

    // ADMIN
    selectedUser: null,
    selectedUserId: null,
    selectedRole: null,
    selectedProject: null,
    userRoles: [],
    projects: [],
    groups: [],
    selectedGroup: null,
    groupTypes: [],
    project_users: [],

    // PROJECTS
    showProjectAddForm: false,

    // PERMISSIONS

    documentAdd: false,
    documentDelete: false,
    documentLock: false,
    documentUnlock: false,
    documentDownload: false,
    documentMove: false,
    documentEditMetadata: false,
    documentViewDocTree: false,

    // PROJECTS
    projectSearchText: "",

    // NOTIFICATIONS
    snackbarOpen: false,
    snackbarMessage: "",
    activeSection: "section1",
  };

  const [initialState, setState] = useState(initState);

  const resetContext = () => {
    setState(initialState);
  };

  const [state, dispatch] = useReducer(QCTMS2Reducer, initialState);

  const setIsSidePanelOpen = (is_side_panel_open) => {
    dispatch({ type: "setIsSidePanelOpen", payload: is_side_panel_open });
  };

  //   Auth
  const update_user_token = (new_user_token) => {
    dispatch({ type: "update_user_token", payload: new_user_token });
  };

  const update_user_id = (new_user_id) => {
    dispatch({ type: "update_user_id", payload: new_user_id });
  };

  const update_user_role = (new_user_role) => {
    dispatch({ type: "update_user_role", payload: new_user_role });
  };

  const update_user_loggen_in = (user_logged_in) => {
    dispatch({ type: "update_user_loggen_in", payload: user_logged_in });
  };

  const setLoggedInUserName = (logged_in_user_name) => {
    dispatch({ type: "setLoggedInUserName", payload: logged_in_user_name });
  };

  const setLoggedInUserEmail = (logged_in_user_email) => {
    dispatch({ type: "setLoggedInUserEmail", payload: logged_in_user_email });
  };

  const update_project_id = (project_id) => {
    dispatch({ type: "update_project_id", payload: project_id });
  };
  const update_project_name = (project_name) => {
    dispatch({ type: "update_project_name", payload: project_name });
  };
  const setSelectedDocumentName = (document_name) => {
    dispatch({ type: "setSelectedDocumentName", payload: document_name });
  };
  const setselectedFolderId = (folder_id) => {
    dispatch({ type: "setselectedFolderId", payload: folder_id });
  };
  const setSelectedFileIndex = (selected_file) => {
    dispatch({ type: "setSelectedFileIndex", payload: selected_file });
  };
  const setTreeData = (treeData) => {
    dispatch({ type: "setTreeData", payload: treeData });
  };
  const setOpenFolderList = (open_folder_list) => {
    dispatch({ type: "setOpenFolderList", payload: open_folder_list });
  };
  const setSelectedFolderName = (selectedFolderName) => {
    dispatch({ type: "setSelectedFolderName", payload: selectedFolderName });
  };
  const setisFileMoveDialogOpen = (isFileMoveDialogOpen) => {
    dispatch({
      type: "setisFileMoveDialogOpen",
      payload: isFileMoveDialogOpen,
    });
  };
  const setIsEditPlaceholderDialogOpen = (isEditPlaceholderDialogOpen) => {
    dispatch({
      type: "setIsEditPlaceholderDialogOpen",
      payload: isEditPlaceholderDialogOpen,
    });
  };
  const setselectedPlaceholderId = (placeholder_id) => {
    dispatch({
      type: "setselectedPlaceholderId",
      payload: placeholder_id,
    });
  };
  const setselectedDocumentType = (documentType) => {
    dispatch({
      type: "setselectedDocumentType",
      payload: documentType,
    });
  };
  const update_is_system_admin = (is_system_admin) => {
    // console.log("update_is_system_admin", is_system_admin);
    dispatch({
      type: "update_is_system_admin",
      payload: is_system_admin,
    });
  };

  const update_system_admin_permisions = (system_admin_permisions) => {
    // console.log("update_system_admin_permisions", system_admin_permisions);
    dispatch({
      type: "update_system_admin_permisions",
      payload: system_admin_permisions,
    });

    update_current_user_permissions(system_admin_permisions);
  };

  const update_current_user_permissions = (current_user_permissions) => {
    // console.log("update_current_user_permissions", current_user_permissions);
    dispatch({
      type: "update_current_user_permissions",
      payload: current_user_permissions,
    });
  };

  const setSelectedUser = (new_selected_user_admin) => {
    console.log("setSelectedUser", new_selected_user_admin);
    dispatch({
      type: "setSelectedUser",
      payload: new_selected_user_admin,
    });
  };

  const setSelectedRoleAdmin = (new_selected_role_admin) => {
    console.log("setSelectedRoleAdmin", new_selected_role_admin);
    dispatch({
      type: "setSelectedRoleAdmin",
      payload: new_selected_role_admin,
    });
  };

  const setSelectedProject = (new_selected_project_admin) => {
    dispatch({
      type: "setSelectedProject",
      payload: new_selected_project_admin,
    });
  };

  const setUserRoles = (new_user_roles_admin) => {
    console.log("setUserRoles", new_user_roles_admin);
    dispatch({
      type: "setUserRoles",
      payload: new_user_roles_admin,
    });
  };
  const setProjects = (new_projects) => {
    // console.log("setProjects", new_projects);
    dispatch({
      type: "setProjects",
      payload: new_projects,
    });
  };

  const setSelectedGroup = (new_selected_group_admin) => {
    console.log("setSelectedGroup", new_selected_group_admin);
    dispatch({
      type: "setSelectedGroup",
      payload: new_selected_group_admin,
    });
  };

  const setGroups = (new_groups) => {
    console.log("setGroups", new_groups);
    dispatch({
      type: "setGroups",
      payload: new_groups,
    });
  };

  const setGroupTypes = (new_group_types) => {
    console.log("setGroupTypes", new_group_types);
    dispatch({
      type: "setGroupTypes",
      payload: new_group_types,
    });
  };

  //   PROJECTS
  const setShowProjectAddForm = (new_show_project_add_form) => {
    console.log("setShowProjectAddForm", new_show_project_add_form);
    dispatch({
      type: "setShowProjectAddForm",
      payload: new_show_project_add_form,
    });
  };

  const setProjectUsers = (new_project_users) => {
    console.log("setProjectUsers", new_project_users);
    dispatch({
      type: "setProjectUsers",
      payload: new_project_users,
    });
  };

  // DOCUMENT-PERMISSIONS
  const setDocumentAdd = (new_document_add) => {
    console.log("setDocumentAdd", new_document_add);
    dispatch({
      type: "setDocumentAdd",
      payload: new_document_add,
    });
  };

  const setDocumentDelete = (new_document_delete) => {
    console.log("setDocumentDelete", new_document_delete);
    dispatch({
      type: "setDocumentDelete",
      payload: new_document_delete,
    });
  };

  const setDocumentLock = (new_document_lock) => {
    console.log("setDocumentLock", new_document_lock);
    dispatch({
      type: "setDocumentLock",
      payload: new_document_lock,
    });
  };

  const setDocumentUnlock = (new_document_unlock) => {
    console.log("setDocumentUnlock", new_document_unlock);
    dispatch({
      type: "setDocumentUnlock",
      payload: new_document_unlock,
    });
  };

  const setDocumentDownload = (new_document_download) => {
    console.log("setDocumentDownload", new_document_download);
    dispatch({
      type: "setDocumentDownload",
      payload: new_document_download,
    });
  };

  const setDocumentMove = (new_document_move) => {
    console.log("setDocumentMove", new_document_move);
    dispatch({
      type: "setDocumentMove",
      payload: new_document_move,
    });
  };

  const setDocumentEditMetadata = (new_document_edit_metadata) => {
    console.log("setDocumentEditMetadata", new_document_edit_metadata);
    dispatch({
      type: "setDocumentEditMetadata",
      payload: new_document_edit_metadata,
    });
  };

  const setDocumentViewDocTree = (new_document_view_doctree) => {
    console.log("setDocumentViewDocTree", new_document_view_doctree);
    dispatch({
      type: "setDocumentViewDocTree",
      payload: new_document_view_doctree,
    });
  };

  const setUserDefaultProject = (new_user_default_project) => {
    console.log("setUserDefaultProject", new_user_default_project);
    dispatch({
      type: "setUserDefaultProject",
      payload: new_user_default_project,
    });
  };

  const setProjectSearchText = (new_project_search_text) => {
    console.log("setProjectSearchText", new_project_search_text);
    dispatch({
      type: "setProjectSearchText",
      payload: new_project_search_text,
    });
  };
  const setSnackbarOpen = (new_snackbarOpen) => {
    dispatch({
      type: "setSnackbarOpen",
      payload: new_snackbarOpen,
    });
  };

  const setSnackbarMessage = (new_snackbarMessage) => {
    dispatch({
      type: "setSnackbarMessage",
      payload: new_snackbarMessage,
    });
  };

  const setActiveSection = (new_active_section) => {
    dispatch({
      type: "SetActiveSection",
      payload: new_active_section,
    });
  };

  return (
    <QCTMS2Context.Provider
      value={{
        ...state,
        setIsSidePanelOpen,
        // Auth
        update_user_token,
        update_user_id,
        update_user_role,
        update_user_loggen_in,
        update_is_system_admin,
        update_system_admin_permisions,
        update_current_user_permissions,
        setLoggedInUserName,
        setLoggedInUserEmail,
        setUserDefaultProject,
        resetContext,

        // Project INFO
        update_project_id,
        update_project_name,
        // Document related
        setSelectedDocumentName,
        setselectedFolderId,
        setSelectedFileIndex,
        // Document Tree related
        setTreeData,
        setOpenFolderList,

        setSelectedFolderName,
        setisFileMoveDialogOpen,

        // Dialog boxes
        setIsEditPlaceholderDialogOpen,
        setselectedPlaceholderId,
        setselectedDocumentType,

        // ADMIN
        setSelectedUser,
        // setSelectedUser,
        setSelectedRoleAdmin,
        setSelectedProject,
        setUserRoles,
        setProjects,
        setGroups,
        setSelectedGroup,
        setGroupTypes,

        // PROJECTS
        setShowProjectAddForm,
        setProjectUsers,

        // DOCUMENT-PERMISSIONS
        setDocumentAdd,
        setDocumentDelete,
        setDocumentLock,
        setDocumentUnlock,
        setDocumentDownload,
        setDocumentMove,
        setDocumentEditMetadata,
        setDocumentViewDocTree,

        // SEARCH
        setProjectSearchText,
        // NOTIFICATIONS
        setSnackbarOpen,
        setSnackbarMessage,
        setActiveSection,
      }}
    >
      {children}
    </QCTMS2Context.Provider>
  );
};

QCTMS2Provider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default QCTMS2Context;
