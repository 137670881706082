import React from "react";
import { Box } from "@mui/material";
import { Typography } from "@mui/material";

const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{
        p: 3,
        bgcolor: "background.paper",
        boxShadow: "0px -5px 5px -5px rgba(0,0,0,0.2)", // top and bottom shadow
        borderTop: "1px solid rgba(0, 0, 0, 0.12)", // subtle top border for separation }}>
        height: "64px",
      }}
    >
      <Typography variant="body2" color="text.secondary" align="center">
        {"© "}
        pamfili.co
        {"."}
      </Typography>
    </Box>
  );
};

export default Footer;
