import React from "react";
import { Routes, Route } from "react-router-dom";
import { Container } from "@mui/material";
// MY COMPONENTS
import HelloAll from "./MainPage";

const ContentContainer = () => {
  return (
    <Container
      component="main"
      maxWidth={false}
      disableGutters
      sx={{
        flexGrow: 1,
        height: "calc(100vh - 64px - 100px)",
        mt: 0,
        mb: 0,
        overflow: "auto",
      }}
    >
      <Routes>
        <Route path="/" element={<HelloAll />} />
      </Routes>
    </Container>
  );
};

export default ContentContainer;
