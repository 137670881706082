import "./App.css";
import * as React from "react";
import { Box, CssBaseline, Snackbar } from "@mui/material";

import { ThemeProvider, createTheme } from "@mui/material/styles";

// MY COMPONENTS
import Header from "./Header";
import Footer from "./Footer";
// import MenuDrawer from "./MenuDrawer";
import ContentContainer from "./ContentContainer";
import { useQCTMS2 } from "./contextapp";
import CarAnimation from "./CarAnimation";

// TODO: fd69fffa-7b5f-4d6a-8a71-b3a77b186217 - handle this somehow with an environment variable
// window.console.log = () => {};

const theme = createTheme({
  components: {
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fontSize: 30,
        },
      },
    },
  },
});
const App = () => {
  const { snackbarOpen, setSnackbarOpen, snackbarMessage } = useQCTMS2();
  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
          //   backgroundColor: 'black', // Set the background to dark
          //   position: 'relative', // For absolute positioning of cars
        }}
      >
        {/* <CarAnimation /> */}
        <CssBaseline />
        <Header />
        <ContentContainer />
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={() => setSnackbarOpen(false)}
          message={snackbarMessage}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        />
        <Footer />
      </Box>
    </ThemeProvider>
  );
};

export default App;
